import { jsx } from "react/jsx-runtime";
import { createStore } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { createContext, useRef, useContext } from "react";
const createState = (name, initialState, mergeInitialProps = (s, p) => ({ ...s, ...p })) => {
  const createStore$1 = (initProps) => {
    return createStore()(
      (set, get) => mergeInitialProps(initialState(set, get), initProps ?? {})
    );
  };
  const Context = createContext(null);
  function ContextProvider({
    children,
    ...props
  }) {
    const storeRef = useRef();
    if (!storeRef.current) {
      storeRef.current = createStore$1(props);
    }
    return /* @__PURE__ */ jsx(Context.Provider, { value: storeRef.current, children });
  }
  const useStore = (selector, equalityFn) => {
    const store = useContext(Context);
    if (!store) {
      throw new Error(`Missing ${name} provider in the tree`);
    }
    return useStoreWithEqualityFn(store, selector, equalityFn);
  };
  return {
    ContextProvider,
    useStore
  };
};
export {
  createState
};
